import LibGenerateTestUserSig from './lib-generate-test-usersig-es.min.js';
/**
 *  time: 7 * 24 * 60 * 60 = 604800 = 7days
 */
const EXPIRETIME = 604800;

function genTestUserSig(options) {
    let { SDKAppID, secretKey, userID } = options;
    SDKAppID = Number(SDKAppID)
    const generator = new LibGenerateTestUserSig(SDKAppID, secretKey, EXPIRETIME);
    const userSig = generator.genTestUserSig(userID);
    return {
        SDKAppID,
        userSig,
    };
}

export { genTestUserSig, EXPIRETIME };