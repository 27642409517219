import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { register } from "@/utils/IM/REGISTER/register.js";
import { users_table_state } from './type'
import axios from "axios";
import Cache from "@/utils/cache";
import { ElMessage } from "element-plus";

// let baseUrl = "https://tts.fsycxkj.com";
// let baseUrl = "https://node.nsyijiefang.com";
let baseUrl = process.env.VUE_APP_NODE_BASE_URL;
const users_tableModule:Module<users_table_state,IRootState>={
    namespaced:true,
    state(){
        return{
            users_table_List: [],
            users_table_Count: 0,//数据条数
            group_info:{},
            group_id:"",//群的ID
            ban_ids:[],//被禁言的用户
            show_group:false,
        }
    },
    mutations:{
        change_users_table_List(state, users_table_List: any[]) {
            state.users_table_List = users_table_List
          },
          change_users_table_Count(state, users_table_Count: number) {
            state.users_table_Count = users_table_Count
          },
          change_group_id(state, group_id: any) {
            state.group_id = group_id
            // console.log(state,group_id,'群的编号为')
          },
          change_group_info(state, group_info: any) {
            state.group_info = group_info
          },
          change_show_group(state,show_group:any){
            state.show_group = show_group
          },
          change_ban_ids(state,ban_ids:any){
            state.ban_ids = ban_ids
          }
    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_Count`]//选择对应数据条数
            }
          },
          pageGetApplyInfo() {
            return async (queryInfo: any) => {
                let user_id = Cache.getCache('im_userId')
                let dept_id = Cache.getCache('im_deptId')
                const pageResult:any = await axios.get(baseUrl+"/custom/get_group_ifno/imApproval",{
                params:{
                  "user_id":user_id,
                  "dept_id":dept_id
                }
              })
              console.log(pageResult,'看看请求结果')
              let {data} = pageResult
              if(data.code==200)
              {
                return data.data
              }else{
                return null
              }
            }
          },
          pageGetGroupInfo() {
            return async (queryInfo: any) => {
              // let user_id = Cache.getCache('im_userId')
              let dept_id = Cache.getCache('im_deptId')
                const pageResult:any = await axios.get(baseUrl+"/custom/get_group_ifno/imCommunityGroup",{
                params:{
                  "dept_id":dept_id,
                }
              })
              console.log(pageResult,'看看请求结果')
              let {data} = pageResult
              if(data.code==200)
              {
                return data.data
              }else{
                return null
              }
            }
          },
    },
    actions:{
      async editGroupMember({commit},payload:any){
        let option =payload
         let res = await register('18042800631', 'v4/group_open_http_svc/modify_group_member_info', option)
				if (res.status == 200 && res.data.ActionStatus == "OK") {
          commit('change_show_group',false)
          ElMessage({
            message: "修改成功",
            type: "success",
          });
				}
      },
      async addGroupMember({commit},payload:any){
        let option = {
          "GroupId":payload.GroupId,
          "MemberList":payload.MemberList
        }
         let res = await register('18042800631', 'v4/group_open_http_svc/add_group_member', option)
				if (res.status == 200 && res.data.ActionStatus == "OK") {
          commit('change_show_group',false)
          ElMessage({
            message: "修改成功",
            type: "success",
          });
          console.log('拉人成功')
				}
        // console.log(payload,'看看传过来新增的id编号')
      },
      async delGroupMember({commit},payload:any){
        let MemberToDel_Account = payload.MemberToDel_Account.map(v=>v.toString())
        let option = {
          "GroupId":payload.GroupId,
          "MemberToDel_Account":MemberToDel_Account
        }
         let res = await register('18042800631', 'v4/group_open_http_svc/delete_group_member', option)
         console.log(res,'看看删除的结果')
				if (res.status == 200 && res.data.ActionStatus == "OK") {
          commit('change_show_group',false)
          ElMessage({
            message: "修改成功",
            type: "success",
          });
          console.log('踢人成功')
				}
        console.log(option,'看看传过来删除的id编号')

      },
      async changeGroupShow({ commit }, payload: any) {
        commit('change_show_group',payload)
     },
      async changeGroupId({ commit }, payload: any){
        // console.log(payload,'改变群的ID')
        let groupID = payload.groupID
        commit('change_group_id',payload.groupID)
        let option = {
          GroupIdList:[groupID]
        }
      let res = await register(1, "v4/group_open_http_svc/get_group_info", option);
        // console.log(res,'看看获取群详情列表')
        if(res.statusText == "OK" && res.status == 200)
          {
            let GroupInfo:any = res.data.GroupInfo
            console.log(GroupInfo,'看看当前群组的详情')
            commit('change_group_info',{...GroupInfo[0]})
            commit('change_show_group',true)
          }
        let ban_option = {
          "GroupId": `${groupID}`
        }
      let ban_res = await register(1, "v4/group_open_http_svc/get_group_muted_account", ban_option);
        if(ban_res.statusText == "OK" && ban_res.status == 200)
          {
            let ban_users : any = ban_res.data.MutedAccountList.map(v=>Number(v.Member_Account))
            // console.log(ban_users,'看看所有被禁言的用户的id')
            commit('change_ban_ids',ban_users)
          }
        // console.log(ban_res,'看看本群被禁言的用户')
      },
      async getPageListAction({ commit }, payload: any) {
         // // 1.获取pageUrl

      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/${pageName}`
        // await createPageData(pageUrl, newData)

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },

      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/${pageName}/${id}`

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },

      async addGroupDataAction({dispatch},payload:any){

      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { pageName, id } = payload
        const pageUrl = `/${pageName}/${id}`
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)

        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: { }
        })
      },
    }
}

export default users_tableModule
