<template>
  <div>
    <router-view></router-view>
  </div>
  <!-- <el-config-provider :locale="locale">

  </el-config-provider> -->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cache from "@/utils/cache";
// import { TUILogin } from "@tencentcloud/tui-core";
// import { framework } from "@/TUIKit/adapter-vue";
import { loginChat, loginGetSig } from "@/utils/IM/login";
// import ElementPlus from 'element-plus'
// import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
// change language to chinese

export default defineComponent({
  name: "App",
  setup() {
    return {
    };
  },
});
</script>

<style lang="less">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
// }
</style>
