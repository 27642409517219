 export default {
     setCache(key, value) {
         uni.setStorageSync(key, JSON.stringify(value))
     },

     getCache(key) {
         // obj => string => obj
         let value = uni.getStorageSync(key)
             // if (value&&value.indexOf('{')!==-1) {
             //   return JSON.parse(value)
             // }
         return value
     },

     deleteCache(key) {
         uni.removeStorageSync(key)
     },

     clearCache() {
         uni.clearStorageSync()
     },
 }