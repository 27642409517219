const modules:any = {};

import users_tableModule from './users_table';
 modules['users_tableModule'] = users_tableModule


import consult_infoModule from './consult_info';
modules['consult_infoModule'] = consult_infoModule

import quest_infoModule from './quest_info';
 modules['quest_infoModule'] = quest_infoModule

import question_detail_infoModule from './question_detail_info';
modules['question_detail_infoModule'] = question_detail_infoModule


import group_infoModule from './group_info';
modules['group_infoModule'] = group_infoModule


export { modules }
