// import chat from './index.js'
import cache from '../cache.js'
import { genTestUserSig } from "../SIG/GenerateTestUserSig.js"
import { TUILogin } from '@tencentcloud/tui-core';
import axios from "axios";
let SDKAppID = process.env.VUE_APP_IM_BASE_APPID;
let secretKey = process.env.VUE_APP_IM_BASE_SIGN;
let identifier = process.env.VUE_APP_IM_BASE_NAME;
let area = 'console.tim.qq.com' //中国域名
    // let path = 'v4/im_open_login_svc/account_import'//请求接口
    // let identifier = 'nsyijiefang' //管理员账号
let usersig = '' //对应的签名
let random = Math.ceil(Math.random() * Math.pow(10, 6)); //随机数0~32位
let contenttype = 'json'

export function loginGetSig(userID) {
    let option = {}
        // console.log(userID,'看看传过来的用户ID',typeof(userID))
    option["userID"] = userID
    option["SDKAppID"] = SDKAppID
    option["secretKey"] = secretKey
    let result_sig = genTestUserSig(option)
        // console.log(result_sig,'看看签名的内容')
    let userSig = result_sig["userSig"] ? result_sig["userSig"] : ''
    cache.setCache('userSig', userSig)
    return userSig
}

export function register(userID, path, data) {
    let option = {}
        // console.log(userID,'看看传过来的用户ID',typeof(userID))
    option["userID"] = identifier
    option["SDKAppID"] = SDKAppID
    option["secretKey"] = secretKey
    let result_sig = genTestUserSig(option)
    let userSig = result_sig["userSig"] ? result_sig["userSig"] : ''
        // contenttype = {
        // 	  "CheckItem":
        // 	  [
        // 	      {
        // 	          "UserID":userID
        // 	      },
        // 	  ]
        // }
    let url = `https://${area}/${path}?sdkappid=${SDKAppID}&identifier=${identifier}&usersig=${userSig}&random=${random}&contenttype=${contenttype}`
    let result = new Promise((resolve, reject) => {
        axios({
            url, // 你的API地址
            method: 'POST',
            data,
            header: {
                'content-type': 'application/json' // 默认值
            }
        }).then(res => {
            resolve(res)
            console.log('请求成功')
        }).catch(err => {
            console.log('请求失败')
            reject(err)
        });
    })
    return result
}