import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { register } from "@/utils/IM/REGISTER/register.js";
import { data_state } from './type'
import axios from "axios";
import Cache from "@/utils/cache";
import { ElMessage } from "element-plus";

// let baseUrl = "https://tts.fsycxkj.com";
// let baseUrl = "https://node.nsyijiefang.com";
let baseUrl = process.env.VUE_APP_NODE_BASE_URL

const dataModule:Module<data_state,IRootState>={
    namespaced:true,
    state(){
        return{
            data_List: [],
            data_Count: 0,//数据条数
            group_info:{},
            group_id:"",//群的ID
            show_group:false,
        }
    },
    mutations:{
        change_data_List(state, data_List: any[]) {
            state.data_List = data_List
          },
          change_data_Count(state, data_Count: number) {
            state.data_Count = data_Count
          },
          change_group_id(state, group_id: any) {
            state.group_id = group_id
            // console.log(state,group_id,'群的编号为')
          },
          change_group_info(state, group_info: any) {
            state.group_info = group_info
          },
          change_show_group(state,show_group:any){
            state.show_group = show_group
          },

    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_Count`]//选择对应数据条数
            }
          },
          pageGetApplyInfo() {
            return async (queryInfo: any) => {
                let user_id = Cache.getCache('im_userId')
                let dept_id = Cache.getCache('im_deptId')
                const pageResult:any = await axios.get(baseUrl+"/custom/get_group_ifno/imApproval",{
                params:{
                  "user_id":user_id,
                  "dept_id":dept_id
                }
              })
              console.log(pageResult,'看看请求结果')
              let {data} = pageResult
              if(data.code==200)
              {
                return data.data
              }else{
                return null
              }
            }
          },
          pageGetGroupInfo() {
            return async (queryInfo: any) => {
              // let user_id = Cache.getCache('im_userId')
              let dept_id = Cache.getCache('im_deptId')
                const pageResult:any = await axios.get(baseUrl+"/custom/get_group_ifno/imCommunityGroup",{
                params:{
                  "dept_id":dept_id,
                }
              })
              console.log(pageResult,'看看请求结果')
              let {data} = pageResult
              if(data.code==200)
              {
                return data.data
              }else{
                return null
              }
            }
          },
    },
    actions:{

      async changeGroupShow({ commit }, payload: any) {
        commit('change_show_group',payload)
     },

      async getPageListAction({ commit }, payload: any) {
         // // 1.获取pageUrl

      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/${pageName}`
        // await createPageData(pageUrl, newData)
        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },
      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/${pageName}/${id}`
        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },
      async addPageDataAction({dispatch},payload:any){

      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { pageName, id } = payload
        const pageUrl = `/${pageName}/${id}`
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)

        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: { }
        })
      },
    }
}

export default dataModule
