import { createRouter,createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'

const routes:RouteRecordRaw[] = [
  {path:"/",redirect:'/IM'},
  {path:"/login",name:'login',component:()=>import('../views/login/index.vue')},
  {path:"/IM",name:'IM',component:()=>import('../views/IM/index.vue')},
  {path:"/IM-audit",name:'IM-audit',component:()=>import('../views/IM-audit/index.vue')},
  {path:"/IM-quest",name:'IM-quest',component:()=>import('../views/IM-quest/index.vue')},
  {path:"/IM-question",name:'IM-question',component:()=>import('../views/IM-question/index.vue')},
  {path:"/IM-consult",name:'IM-consult',component:()=>import('../views/IM-consult/index.vue')},
  {path:"/IM-showmarkdown",name:'IM-showmarkdown',component:()=>import('../views/IM-showmarkdown/index.vue')},
  // {path:"/main",name:'main',component:()=>import('../views/main/index.vue')},
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/nofound/index.vue')
  }
]

const router = createRouter({
  routes,
  history:createWebHashHistory()
})

// 导航守卫
router.beforeEach((to) => {
  // if (to.path !== '/login') {
  //   const token = localCache.getCache('token')
  //   if (!token) {
  //     return '/login'
  //   }
  // }

  // console.log(router.getRoutes())
  // console.log(to) // route对象

  if (to.path === '/main') {
    // console.log(firstMenu)
    // return firstMenu.url
  }
})
export default router
