import { Module } from "vuex";

import { ILoginState } from "./types";
import { IRootState } from "../types";

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: "",
      userInfo: {},
      userMenus: [],
      permissions: [],
    };
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenus(state, userMenus: any) {
      // state.userMenus = userMenus
      // if(menus)
      // {
      //   state.userMenus[2].children = Object.values(menus)
      // 将routes => router.main.children


      // 获取用户按钮的权限
      // const permissions = mapMenusToPermissions(userMenus)
      // state.permissions = permissions
    }
  },
  actions: {
    accountLoginOut({ commit }) {
      commit('loginOut')
    },
    async accountLoginIn({ commit }, payload: any) {
      console.log(payload);
      // 1.实现登录逻辑
      // console.log(loginResult);
    },
    accountLoginAction({ commit }, payload: any) {
      console.log('登陆里面的Action', payload)
      commit('changeUserMenus')
    }
  },
};

export default loginModule;
